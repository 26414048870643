import React from "react";

interface TeamItemProps {
  teamName: string;
  teamLogo: string;
  customClass?: string;
  displayLogos?: boolean;
}

function TeamItem({ teamName, teamLogo, customClass ="", displayLogos = true }: TeamItemProps) {

  return (
    <div className={`match-team ${customClass}`}>
      <div className="team-name">{teamName}</div>

      {displayLogos===true ?
        <>
          <div className="team-logo">
            {teamLogo === '' ?
              <img src="../../assets/placeholder/placeholder-800.webp" alt={`Team Logo ${teamName}`} width={32} height={32}/>
              :
              <img src={teamLogo} alt={`Team Logo ${teamName}`} width={32} height={32}/>
            }
          </div>
        </>
        :
        <></>
      }
    </div>
  );
}

export default TeamItem;